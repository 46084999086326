import openai from "../services/ai";
import { getFilterQueryStringWithoutArray, baseURL } from "@/helpers/helper.js";

const getDefaultState = () => {
  return {
    partnersPages: [],
    partnersPagesWithOrg: [],
    generatePartnerPage: [],
    allProfilePages: [],
    profilePage: [],
    profilePageStatus: "",
  };
};

const state = getDefaultState();

const getters = {
  partnersProfilePages: (state) => state.partnersPages,
  partnersPagesWithOrg: state => state.partnersPagesWithOrg,
  generatedPartnerRes: (state)  => state.generatePartnerPage?.length ? state.generatePartnerPage[0]: null,
  getAllProfilePages: (state) =>
    state.allProfilePages?.length
      ? state.allProfilePages.map((profile) => {
        return {
          link_to_doc: `${baseURL()}${profile[0]?.link_to_doc}?preview=true`,
          doc_name: profile[0]?.document_name,
          partners_page_uuid: profile[1]?.partners_page_uuid,
          organisation_name: profile[2]?.name,
          web_url: `${baseURL()}/view/profile-page/${profile[1]?.partners_page_uuid}`
        };
      })
      : [],
  getProfilePage: (state) =>
    state.profilePage
      ? {
          ...state.profilePage,
          link_to_doc: `${baseURL()}${state.profilePage?.link_to_doc}?preview=true`,
        }
      : {},
  getProfilePageStatus: (state)  => state.profilePageStatus,
};

const mutations = {
  ["SET_PARTNERS_PAGES"](state, data) {
    state.partnersPages = data;
  },
  ["RESET_PARTNERS_PAGES"](state) {
    state.partnersPages = [];
  },
  ["SET_GENERATED_PARTNER_RES"](state, data) {
    state.generatePartnerPage = data;
  },
  ["SET_PARTNERS_PAGES_WITH_ORG"](state, data) {
    state.partnersPagesWithOrg = data;
  },
  ["RESET_PARTNERS_PAGES_WITH_ORG"](state) {
    state.partnersPagesWithOrg = [];
  },
  ["SET_ALL_PARTNER_PAGES"](state, data) {
    state.allProfilePages = data;
  },
  ["SET_PARTNER_PAGE"](state, data) {
    state.profilePage = data;
  },
  ["SET_PARTNER_PAGE_STATUS"](state, data) {
    state.profilePageStatus = data;
  }
};

const actions = {
  generateTemplate: ({ commit, dispatch }, data) => {
    const {
      name,
      description,
      organisation_id,
      template,
      home_url,
      login_url,
      register_url
    } = data;
    const formData = new FormData();
    let message = `Create a profile page for ${name}. Get the content details for the each section from given document. 
                  If content is not available for particular section, you generate your own content with more relevant. 
                  The generate html should contain all sections. A short Description for this profile is "${description}".
                  Additionally, include a header with:
                    - A logo image must be scaled to fit within the header.
                    - Three menu items with the following URLs (**open in a new tab**):
                      - Home: ${home_url}
                      - Login: ${login_url}
                      - Register: ${register_url}
                    `;
    if(data.link){
      const {
        link,
      } = data;
      formData.append("link", link);
    }else{
      const {file: {file, fileName}} = data;
      formData.append("file", file, fileName);
    }

    if(template === "template2"){
      message = `${message}. Remove the teams, testimonials sections from the generated html.`;
    }
    formData.append("message", message);
    return openai
      .generatePartnerPage(formData, organisation_id)
      .then((res) => {
        dispatch("showToast", {
          message: "Profile Page Generation Started...",
          class: "bg-success text-white",
        });
        setTimeout(() => {
          dispatch("showToast", {
            message: "Refresh the page after some time...",
            class: "bg-success text-white",
          });
        }, 3000);
        return res;
      })
      .catch((err) => {
        const message = "Error in Template Creation";
        dispatch("showToast", {
          message,
          class: "bg-danger text-white",
        });
        console.log(message, err);
        return err;
      });
  },
  createPartnerPage: ({ commit, dispatch }, payload) => {
    const {
      organisation_id,
      file: { file, fileName },
    } = payload;
    const formData = new FormData();
    formData.append("file", file, fileName);
    dispatch("showLoader");
    return openai
      .createPartnerPage({ organisation_id, payload: formData })
      .then((res) => {
        dispatch("hideLoader");
        dispatch("showToast", {
          message: "Profile Page Created Successfully",
          class: "bg-success text-white",
        });
        return res;
      })
      .catch((err) => {
        const message = "Error in Profile Page Creation";
        dispatch("hideLoading");
        dispatch("showToast", {
          message,
          class: "bg-danger text-white",
        });
        console.log(message, err);
        return err;
      });
  },
  getPartnersPages: ({ commit, dispatch }, payload) => {
    commit("RESET_PARTNERS_PAGES");
    const query = getFilterQueryStringWithoutArray(payload);
    return openai
      .getPartnerPage(query)
      .then((res) => {
        const { data } = res;
        commit("SET_PARTNERS_PAGES", data);
        return res;
      })
      .catch((err) => {
        const message = "Error in Getting Profile Page";
        dispatch("showToast", {
          message,
          class: "bg-danger text-white",
        });
        console.log(message, err);
        return err;
      });
  },
  getPartnersPagesWithOrg: ({ commit, dispatch }) => {
    commit("RESET_PARTNERS_PAGES_WITH_ORG");
    return openai
      .getPartnerPageWithOrg()
      .then((res) => {
        const { data } = res;
        commit("SET_PARTNERS_PAGES_WITH_ORG", data);
        return res;
      })
      .catch((err) => {
        const message = "Error in Getting Profile Page";
        dispatch("showToast", {
          message,
          class: "bg-danger text-white",
        });
        console.log(message, err);
        return err;
      });
  },
  getHTML: ({ commit, dispatch, getters }, url) => {
    return openai
      .getHTML(url)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.error(err);
        return err;
      });
  },
  getGenerateResponse: ({ commit, dispatch, getters }, organisation_id) => {
    const queryPayload = {
      all_rows: true,
      fetch_row_count: false,
      order_by: "-partners_page_id",
      limit: 1,
      organisation_id,
    };
    const queryString = getFilterQueryStringWithoutArray(queryPayload);

    return openai
      .getGenerateResponse(queryString)
      .then((res) => {
        const data = res.data;
        const finalData = data?.map((v) => ({
          ...v,
          assistant_message: JSON.parse(v.assistant_message),
        }));
        commit("SET_GENERATED_PARTNER_RES", finalData);
        return finalData;
      })
      .catch((err) => {
        const message = "Error in Getting Generation Status";
        dispatch("showToast", {
          message,
          class: "bg-danger text-white",
        });
        console.log(message, err);
        return err;
      });
  },
  getUnsplashImage: ({ commit, dispatch }, query) => {
    return openai
      .getUnsplashImages(query)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.error(err);
        return err;
      });
  },
  stopPartnerPageGeneration: ({ commit, dispatch }, partner_page_id) => {
    return openai
      .stopPartnerPageGeneration(partner_page_id)
      .then((res) => {
        if(res.data.status == "error") {
          dispatch("showToast", {
            message: res.data.message || "Error in Stop Generation",
            class: "bg-danger text-white",
          });
        } else {
          dispatch("showToast", {
            message: "Generation Stopped Successfully",
            class: "bg-success text-white",
          });
        }
        return res;
      })
      .catch((err) => {
        console.error(err);
        const message = err.message||"Error in Stop Generation";
        dispatch("showToast", {
          message,
          class: "bg-danger text-white",
        });
        return err;
      });
  },
  fetchAllProfilePages: async ({ commit }) => {
    try {
      const res = await openai.getAllProfilePages();
      commit("SET_ALL_PARTNER_PAGES", res.data);
      return res;
    } catch (err) {
      console.error("Error fetching all profile pages:", err);
      return err;
    }
  },
  fetchProfilePage: async ({ commit }, partner_uuid) => {
    try {
      const res = await openai.getProfilePage(partner_uuid);
      commit("SET_PARTNER_PAGE", res.data);
      return res;
    } catch (err) {
      console.error(`Error fetching profile page for UUID ${partner_uuid}:`, err);
      return err;
    }
  },
  fetchProfilePageStatus: async ({ commit }, org_id) => {
    try {
      const res = await openai.getProfilePageStatus(org_id);
      commit("SET_PARTNER_PAGE_STATUS", res.data);
      return res;
    } catch (err) {
      console.error(`Error fetching profile page status`, err);
      return err;
    }
  }
};

export default { state, getters, mutations, actions };
